import type {ClientStatusPublic} from '@wix/cashier-common/dist/src/types/PaymentStatus'
import {PlanInfo} from '@wix/events-types'
import {HttpError} from '@wix/http-client'
import {parseQueryParams, waitALittle} from '@wix/panda-js-utils'
import {EVENTS_APP_ID, EVENTS_DETAILS_PAGE_ID, getCouponDiscountCode} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {ReservationState} from '../../../../commons/enums'
import {getLocale} from '../../../../commons/selectors/environment'
import {confirmPaymentSuccessStatuses} from '../constants'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {getCouponCode} from '../selectors/invoice'
import {getNavigationQuery, getPaymentRedirectUrl} from '../selectors/navigation'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {isRequestPending} from '../selectors/pending-requests'
import {getInvoice, isPreparingOrder, onlyFreeTicketsSelected} from '../selectors/placed-order'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, RegFormData, StoreExtraArgs} from '../types'
import {extractFormData} from '../utils/api-data-mapper'
import {Interaction} from '../utils/interactions'
import {navigateTo, navigateToLinkExpired, navigateToNotFound, navigateToOrder} from './navigation'
import {placeOrderButtonClicked} from './payment'

export const PLACE_ORDER = createActions('PLACE_ORDER')
export const UPDATE_ORDER = createActions('UPDATE_ORDER')
export const GET_ORDER = createActions('GET_ORDER')

export const placeOrder =
  (eventId: string, buyer: RegFormData, guests?: RegFormData[]) =>
  (dispatch: Function, getState: GetState, {flowAPI, wixCodeApi}: StoreExtraArgs) => {
    const state = getState()

    if (isRequestPending(state, PLACE_ORDER.REQUEST)) {
      return
    }

    flowAPI.fedops.interactionStarted(Interaction.Checkout)

    const {membersAreaEnabled} = state
    const couponCode = getCouponDiscountCode(getInvoice(state))
    const memberData = getCurrentMemberDetails(state)
    const memberId = membersAreaEnabled && memberData ? memberData.id : undefined
    const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
    const {benefitId, planOrderId} = selectedPlan
    const paymentRedirectUrl = getPaymentRedirectUrl(state, wixCodeApi)

    const orderGuests = guests ? guests.map(extractFormData) : null

    return dispatch(
      callAPI(PLACE_ORDER, {
        eventId,
        buyer,
        guests: orderGuests,
        couponCode,
        memberId,
        planOrderId,
        benefitId,
        locale: getLocale(state),
        paymentRedirectUrl,
      }),
    ).then((response: any) => {
      const order = response.order

      if (onlyFreeTicketsSelected(order)) {
        dispatch(placeOrderButtonClicked())
      }

      dispatch(postPlaceOrder(order))

      return response
    })
  }

export const postPlaceOrder =
  (order: wix.events.ticketing.Order) =>
  (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    flowAPI.fedops.interactionEnded(Interaction.Checkout)
    const totalPrice = Number(order.totalPrice.amount)
    const newOrderSuccessFlowEnabled = flowAPI.experiments.enabled('specs.events.ui.UseOrderPageUrl')

    if (!totalPrice) {
      if (newOrderSuccessFlowEnabled) {
        dispatch(navigateTo(getState().placedOrder.orderPageUrl))
      } else {
        dispatch(
          navigateToOrder(order.reservationId, ReservationState.SUCCESS, {
            orderNumber: order.orderNumber,
            eventId: order.eventId,
          }),
        )
      }
    }
  }

interface UpdateOrderParams {
  buyer?: RegFormData
  guests?: RegFormData[]
  paymentDetailsId?: string
}

export const updateOrder =
  ({buyer, guests, paymentDetailsId}: UpdateOrderParams) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    const eventId = state.event.id
    const orderNumber = state.placedOrder.order.orderNumber
    const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
    const {benefitId, planOrderId} = selectedPlan

    return dispatch(
      callAPI(UPDATE_ORDER, {
        eventId,
        orderNumber,
        buyer: extractFormData(buyer),
        guests: guests?.map(extractFormData),
        locale: getLocale(state),
        couponCode: getCouponCode(state),
        paidPlanBenefit: benefitId && planOrderId ? {benefitId, planOrderId} : undefined,
        paymentDetailsId,
      }),
    )
  }

export const expressCheckout = createAsyncAction<{clientStatus: ClientStatusPublic}, {paymentDetailsId: string}>(
  'EXPRESS_CHECKOUT',
  async (params, {dispatch, extra: {flowAPI}, getState}) => {
    const state = getState()
    const [{confirmPayment}, updateOrderResponse] = await Promise.all([
      import('@wix/cashier-express-checkout-widget/dist/src/utils/confirmPayment'),
      dispatch(
        updateOrder({
          buyer: state.checkout.buyerDetails,
          guests: state.checkout.ticketsDetails,
          paymentDetailsId: params.paymentDetailsId,
        }),
      ),
    ])
    const {chargeToken, orderPageUrls} = updateOrderResponse

    const confirmPaymentResponse = await confirmPayment({
      meta: {
        appDefId: EVENTS_APP_ID,
        msid: flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId!,
        visitorId: flowAPI.controllerConfig.platformAPIs?.bi?.visitorId!,
        widgetId: EVENTS_DETAILS_PAGE_ID,
      },
      biLoggerFactory: flowAPI.essentials.biLoggerFactory,
      chargeResponseToken: chargeToken,
      redirectFn: flowAPI.controllerConfig.wixCodeApi.location.to,
      newApi: true,
    })
    if (confirmPaymentSuccessStatuses.includes(confirmPaymentResponse.clientStatus)) {
      switch (confirmPaymentResponse.clientStatus) {
        case 'Approved':
          flowAPI.viewerScriptFlowAPI.wixAPI.location.to(orderPageUrls.success)
          break
        case 'Pending':
        case 'InProcess':
          flowAPI.viewerScriptFlowAPI.wixAPI.location.to(orderPageUrls.pending)
          break
      }
    }

    return confirmPaymentResponse
  },
)

export const getOrder =
  (eventId: string, orderNumber: string, token: string = '', retryCount = 0) =>
  (dispatch: Function, getState: GetState) => {
    return dispatch(callAPI(GET_ORDER, eventId, orderNumber, token, getLocale(getState())))
      .then(response => {
        const order = response?.order
        if (isPreparingOrder(order) && retryCount < 15) {
          setTimeout(() => {
            dispatch(getOrder(eventId, orderNumber, token, ++retryCount))
          }, 1500)
        }
      })
      .catch((error: HttpError) => {
        setTimeout(() => {
          if (error.response?.status === 404) {
            dispatch(navigateToNotFound())
          } else {
            dispatch(navigateToLinkExpired())
          }
        })
      })
  }

export const getOrderTicketsDownloadUrl = createAsyncAction<
  {loadingTicketsUrl: boolean; downloadTicketsUrl?: string},
  number
>('GET_ORDER_TICKETS_DOWNLOAD_URL', async (retryLimit, {dispatch, getState, extra: {serverApi, flowAPI}}) => {
  const newOrderSuccessFlowEnabled = flowAPI.experiments.enabled('specs.events.ui.UseOrderPageUrl')
  const {token: orderToken} = getNavigationQuery(getState())

  // remove getting token from ticketsPdf when specs.events.ui.UseOrderPageUrl is merged!
  const ticketsPdfLink = getState().placedOrder.order.ticketsPdf
  const {request, file} = parseQueryParams(ticketsPdfLink) || {}

  // && orderToken can be removed with newOrderSuccessFlowEnabled, check express checkout
  const token = newOrderSuccessFlowEnabled && orderToken ? orderToken : request ?? file
  try {
    const response = await serverApi.getPapyrusTicketsDownloadUrl({token})
    if (response.ready && response.downloadUrl) {
      flowAPI.fedops.interactionEnded(Interaction.LoadingTickets)
      return {loadingTicketsUrl: !response.ready, downloadTicketsUrl: response.downloadUrl}
    }
    if (retryLimit > 0) {
      await waitALittle()
      dispatch(getOrderTicketsDownloadUrl(--retryLimit))
    } else {
      throw new Error(`Automatic retry limit exceeded, please retry by clicking download`)
    }
    return {loadingTicketsUrl: !response?.ready, downloadTicketsUrl: response?.downloadUrl}
  } catch (e) {
    console.error({e})
    if (retryLimit > 0) {
      await waitALittle()
      dispatch(getOrderTicketsDownloadUrl(--retryLimit))
    } else {
      return {loadingTicketsUrl: false}
    }
  }
})
